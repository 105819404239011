import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
// Move to styled components
const useStyles = makeStyles((theme) => ({
  active: { marginLeft: '13.5%' }
}));

export default function CheckBoxGroup({ formState, handleValueChange }) {
  const classes = useStyles();

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={formState.is_colocated}
            onChange={(event) =>
              handleValueChange({
                name: 'is_colocated',
                newValue: event.target.checked
              })
            }
            value="is_colocated"
          />
        }
        className={classes.colocated}
        label="Is Colocated?"
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={!!formState.is_reportable}
            onChange={(event) =>
              handleValueChange({
                name: 'is_reportable',
                newValue: event.target.checked
              })
            }
            value="is_reportable"
          />
        }
        className={classes.colocated}
        label="Is Reportable?"
      /> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={!!formState.observes_dst}
            onChange={(event) =>
              handleValueChange({
                name: 'observes_dst',
                newValue: event.target.checked
              })
            }
            value="observes_dst"
          />
        }
        className={classes.colocated}
        label="Observes DST?"
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={!!formState.is_quickpay}
            onChange={(event) =>
              handleValueChange({
                name: 'is_quickpay',
                newValue: event.target.checked
              })
            }
            value="is_quickpay"
          />
        }
        className={classes.colocated}
        label="Is Quickpay?"
      /> */}
      {/* Removed due to request from BIE, May need to be reactivated based on needs
      <FormControlLabel
        control={
          <Checkbox
            checked={!!formState.is_active}
            onChange={event =>
              handleValueChange({
                name: 'is_active',
                newValue: event.target.checked
              })
            }
            value='is_active'
          />
        }
        className={classes.colocated}
        label='Is Active?'
      /> */}
    </div>
  );
}
