import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { makeStyles } from '@material-ui/core';
import data from './SpinnerDialogs';
const useStyles = makeStyles(theme => ({
  loader: {
    flexGrow: 1,
    display: 'absolute'
  },
  divider: {
    height: theme.spacing(6)
  }
}));

export default function Loader() {
  const classes = useStyles;
  const random = () => {
    return Math.floor(Math.random() * data.length);
  };

  return (
    <div
      className={classes.loader}
      style={{ marginTop: '15%', marginLeft: '45%' }}
    >
      <PacmanLoader color={'#123abc'} />
      <br />
      <br />
      <div style={{ fontWeight: '900' }}>{data[random()]}</div>
    </div>
  );
}
