import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { ReactComponent as Logo } from '../svg/logo.svg';
import { Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, AvatarText } from '@amzn/stencil-react-components/avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useEmpInfoState } from '../Context/EmpInfoContext';

const useStyles = makeStyles((theme) => ({
  toolbarButtons: {
    marginLeft: 'auto',
    marginRight: -10
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  tabs: {
    marginLeft: 'auto'
  },
  appBar: { backgroundColor: '#252a3e' },
  logo: {
    borderRight: '0.1em solid white',
    padding: '0.01em',
    paddingRight: '0.5em'
  },
  logoText: { color: 'white' }
}));

export default function NavBar({ handleChange, currentTab }) {
  const classes = useStyles();
  const { empInfo, isSuperUser, setIsSuperUser } = useEmpInfoState();
  const [logoState, setLogoState] = useState(false);
  const fullName = `${empInfo.GIVEN_NAME} ${empInfo.FAMILY_NAME}`;
  const username = empInfo.sub;
  const toggleLogo = () => {
    setLogoState(!logoState);
  };
  const toggleSuper = () => {
    setIsSuperUser(!isSuperUser);
  };
  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <span className={classes.menuButton} onClick={toggleLogo}>
            <Logo className={classes.logo} />
          </span>

          <Typography variant="h5" component="h5" className={classes.logoText}>
            BLIP
          </Typography>
          <div className={classes.tabs}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab label="Locations" />
              <Tab label="Business Lines" />
              <Tab label="Requests" />
            </Tabs>
          </div>
          <span className={classes.toolbarButtons} onClick={toggleSuper}>
            <Avatar username={username} fullName={fullName}>
              <AvatarText>{!isSuperUser ? '' : 'Super User'}</AvatarText>
            </Avatar>
          </span>
        </Toolbar>
      </AppBar>
    </div>
  );
}
