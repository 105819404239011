import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import BusinessLineForm from './BusinessLineForm';
import { fetchClient } from '../Utils/globalUtils';
import { useEmpInfoState } from '../Context/EmpInfoContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '50%',
    padding: theme.spacing(2),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  businessline: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  },
  modal: {
    display: 'flex'
  }
}));

export default function BusinessLineTableModal({
  modalOpen,
  toggleModal,
  businessLine,
  attributes,
  isEdit,
  isRequest
}) {
  const classes = useStyles();
  const [validCostCenters, setValidCostCenters] = useState();
  const {
    authState: { session }
  } = useEmpInfoState();
  useEffect(() => {
    const execute = async () => {
      const costCenter = await fetchClient({
        url: 'fetchValidCostCenters',
        jwtToken: session.idToken.jwtToken
      });
      if (attributes) {
        const activatedCenters = Object.keys(attributes).reduce((acc, key) => {
          return attributes[key]['costcenters']
            ? [
                ...attributes[key]['costcenters'].map((cc) => cc.cost_center),
                ...acc
              ]
            : acc;
        }, []);
        let difference = costCenter.filter(
          (x) => !activatedCenters.includes(x)
        );
        setValidCostCenters([...new Set(difference)]);
      }
    };
    execute();
  }, [attributes, session.idToken.jwtToken]);
  return (
    <Modal
      aria-labelledby="Attribute Table Container"
      aria-describedby="Displays and allows editing of all attributes associated with business line"
      open={modalOpen}
      onClose={toggleModal}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <BusinessLineForm
          toggleModal={toggleModal}
          validCostCenters={validCostCenters}
          allBusinessLines={new Set(Object.keys(attributes ? attributes : []))}
          isEdit={isEdit}
          isRequest={isRequest}
          defaultData={
            businessLine
              ? {
                  businessLine: businessLine.businessLine,
                  ...attributes[businessLine.businessLine],
                  threshold: businessLine.threshold
                }
              : {}
          }
        />
      </div>
    </Modal>
  );
}
