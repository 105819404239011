import React from 'react';
import { Button } from '@amzn/stencil-react-components/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  useNotificationState,
  useNotificationDispatch
} from '../Context/NotificationContext';
import { useEmpInfoState } from '../Context/EmpInfoContext';

import { createNewRequest } from '../Utils/globalUtils';
export default function ConfirmationDialog() {
  const { toggleAlert, setNotificationState } = useNotificationDispatch();
  const { params, alertOpen } = useNotificationState();
  const {
    authState: { session }
  } = useEmpInfoState();
  const setConfirmation = () => {
    if (params !== undefined) {
      createNewRequest({
        params,
        setNotificationState,
        jwtToken: session.idToken.jwtToken
      });
      toggleAlert();
    }
  };

  return (
    <div>
      <Dialog
        open={alertOpen}
        onClose={toggleAlert}
        aria-labelledby="Confirmation"
        aria-describedby="confirm whether or not you wish to continue with the action"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to submit this request? Please click agree to
            confirm your request
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleAlert} primary>
            Disagree
          </Button>
          <Button onClick={setConfirmation} primary autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
