import { makeStyles } from '@material-ui/core';
import React from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import querystring from 'querystring';

export const baseUrl = '/api/?callType=';

export const createNewRequest = async ({
  params,
  setNotificationState,
  jwtToken
}) => {
  let url = params['type'] === 'request' ? 'changeRequest' : 'createRequest';

  const response = await fetchClient({ url, params, jwtToken });
  if (response instanceof Error) {
    setNotificationState({
      open: true,
      message: 'Something went wrong, please try again'
    });
  } else {
    setNotificationState({
      open: true,
      message: 'Your request was successfully submitted!'
    });
  }
};

export const fetchClient = async ({ url, params, jwtToken }) => {
  const queryString = querystring.encode(params);
  try {
    const { data } = await axios.get(`${baseUrl}${url}&${queryString}`, {
      withCredentials: true,
      headers: { Authorization: jwtToken }
    });
    return data;
  } catch (e) {
    return e;
  }
};

const useStyles = makeStyles((theme) => ({
  tab_container: {
    padding: 24,
    height: '100%'
  }
}));

// Needed for formatting otherwise styling gets screwed up
export function TabContainer(props) {
  const classes = useStyles();
  return (
    <Typography component="div" className={classes.tab_container}>
      {props.children}
    </Typography>
  );
}

export const groupBy = ({ arr, criteria }) => {
  return arr.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
};

export const groupByCustom = ({ arr, criteria, filter }) => {
  return arr.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = new Set();
    }

    // Push the value to the object
    obj[key].add(item[filter]);

    // Return the object to the next item in the loop
    return obj;
  }, {});
};

export const parseBusinessLineResponse = ({
  businessLineBody,
  fieldNameBline,
  fieldNameSub
}) => {
  const data = businessLineBody.map((b) => {
    const bline = {
      [fieldNameBline]: b['item']['businessLine']
    };

    return bline;
  });

  const attributes = businessLineBody.reduce((acc, next) => {
    const {
      item: {
        subdivisions: subdivisionsRaw = [],
        cost_center: costCenterRaw = [],
        businessLine: bline,
        threshold
      } = {}
    } = next;
    const subdivisions = subdivisionsRaw.map((sd) => {
      return { [fieldNameSub]: sd };
    });
    const costcenters = costCenterRaw.map((cc) => {
      return { cost_center: cc };
    });

    return {
      ...acc,
      [bline]: {
        subdivisions,
        costcenters,
        threshold
      }
    };
  }, {});

  return { data, attributes };
};

export const generateParams = ({ op, data, setParams, type }) => {
  // We want to check the old derived code because if it is set then it would mean we are
  // modifying an existing location. Since the partition key can mutate we have to use
  // this extra attribute to identify what to change
  const operation = op || data.old_derived_code ? 'edit' : 'create';

  const { tableData, ...tmp } = data;
  const extractedFormState = { ...tmp };
  // Must encode because otherwise certain chars(like +) will be replaced with spaces or something
  // else. We also need to stringify it so we can pass it as part of the URI
  if (type === 'request') {
    const { requestTime, ...formValues } = extractedFormState;
    const allLocationValues = { ...formValues };
    setParams({
      data: encodeURIComponent(
        JSON.stringify({
          requestAlias: extractedFormState.update_userid,
          requestTime,
          requestData: encodeURIComponent(JSON.stringify(allLocationValues))
        })
      ),
      operation: 'edit',
      type: 'request'
    });
  } else {
    setParams({
      alias: extractedFormState.update_userid,
      data: encodeURIComponent(JSON.stringify(extractedFormState)),
      op: operation,
      type: type
    });
  }
};

// Fetches the appropriate date formatting based on the user's locale
export function getLocaleDateString(lang) {
  const formats = {
    'ar-SA': 'dd/MM/yy',
    'bg-BG': 'dd.M.yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'zh-TW': 'yyyy/M/d',
    'cs-CZ': 'd.M.yyyy',
    'da-DK': 'dd-MM-yyyy',
    'de-DE': 'dd.MM.yyyy',
    'el-GR': 'd/M/yyyy',
    'en-US': 'M/d/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'he-IL': 'dd/MM/yyyy',
    'hu-HU': 'yyyy. MM. dd.',
    'is-IS': 'd.M.yyyy',
    'it-IT': 'dd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ko-KR': 'yyyy-MM-dd',
    'nl-NL': 'd-M-yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'pl-PL': 'yyyy-MM-dd',
    'pt-BR': 'd/M/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    'hr-HR': 'd.M.yyyy',
    'sk-SK': 'd. M. yyyy',
    'sq-AL': 'yyyy-MM-dd',
    'sv-SE': 'yyyy-MM-dd',
    'th-TH': 'd/M/yyyy',
    'tr-TR': 'dd.MM.yyyy',
    'ur-PK': 'dd/MM/yyyy',
    'id-ID': 'dd/MM/yyyy',
    'uk-UA': 'dd.MM.yyyy',
    'be-BY': 'dd.MM.yyyy',
    'sl-SI': 'd.M.yyyy',
    'et-EE': 'd.MM.yyyy',
    'lv-LV': 'yyyy.MM.dd.',
    'lt-LT': 'yyyy.MM.dd',
    'fa-IR': 'MM/dd/yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'hy-AM': 'dd.MM.yyyy',
    'az-Latn-AZ': 'dd.MM.yyyy',
    'eu-ES': 'yyyy/MM/dd',
    'mk-MK': 'dd.MM.yyyy',
    'af-ZA': 'yyyy/MM/dd',
    'ka-GE': 'dd.MM.yyyy',
    'fo-FO': 'dd-MM-yyyy',
    'hi-IN': 'dd-MM-yyyy',
    'ms-MY': 'dd/MM/yyyy',
    'kk-KZ': 'dd.MM.yyyy',
    'ky-KG': 'dd.MM.yy',
    'sw-KE': 'M/d/yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'tt-RU': 'dd.MM.yyyy',
    'pa-IN': 'dd-MM-yy',
    'gu-IN': 'dd-MM-yy',
    'ta-IN': 'dd-MM-yyyy',
    'te-IN': 'dd-MM-yy',
    'kn-IN': 'dd-MM-yy',
    'mr-IN': 'dd-MM-yyyy',
    'sa-IN': 'dd-MM-yyyy',
    'mn-MN': 'yy.MM.dd',
    'gl-ES': 'dd/MM/yy',
    'kok-IN': 'dd-MM-yyyy',
    'syr-SY': 'dd/MM/yyyy',
    'dv-MV': 'dd/MM/yy',
    'ar-IQ': 'dd/MM/yyyy',
    'zh-CN': 'yyyy/M/d',
    'de-CH': 'dd.MM.yyyy',
    'en-GB': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'fr-BE': 'd/MM/yyyy',
    'it-CH': 'dd.MM.yyyy',
    'nl-BE': 'd/MM/yyyy',
    'nn-NO': 'dd.MM.yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'sr-Latn-CS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'az-Cyrl-AZ': 'dd.MM.yyyy',
    'ms-BN': 'dd/MM/yyyy',
    'uz-Cyrl-UZ': 'dd.MM.yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'zh-HK': 'd/M/yyyy',
    'de-AT': 'dd.MM.yyyy',
    'en-AU': 'd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'sr-Cyrl-CS': 'd.M.yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'zh-SG': 'd/M/yyyy',
    'de-LU': 'dd.MM.yyyy',
    'en-CA': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'fr-CH': 'dd.MM.yyyy',
    'ar-DZ': 'dd-MM-yyyy',
    'zh-MO': 'd/M/yyyy',
    'de-LI': 'dd.MM.yyyy',
    'en-NZ': 'd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'fr-LU': 'dd/MM/yyyy',
    'ar-MA': 'dd-MM-yyyy',
    'en-IE': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'fr-MC': 'dd/MM/yyyy',
    'ar-TN': 'dd-MM-yyyy',
    'en-ZA': 'yyyy/MM/dd',
    'es-DO': 'dd/MM/yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'en-JM': 'dd/MM/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'en-029': 'MM/dd/yyyy',
    'es-CO': 'dd/MM/yyyy',
    'ar-SY': 'dd/MM/yyyy',
    'en-BZ': 'dd/MM/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'en-TT': 'dd/MM/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'en-ZW': 'M/d/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'ar-KW': 'dd/MM/yyyy',
    'en-PH': 'M/d/yyyy',
    'es-CL': 'dd-MM-yyyy',
    'ar-AE': 'dd/MM/yyyy',
    'es-UY': 'dd/MM/yyyy',
    'ar-BH': 'dd/MM/yyyy',
    'es-PY': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'es-BO': 'dd/MM/yyyy',
    'es-SV': 'dd/MM/yyyy',
    'es-HN': 'dd/MM/yyyy',
    'es-NI': 'dd/MM/yyyy',
    'es-PR': 'dd/MM/yyyy',
    'am-ET': 'd/M/yyyy',
    'tzm-Latn-DZ': 'dd-MM-yyyy',
    'iu-Latn-CA': 'd/MM/yyyy',
    'sma-NO': 'dd.MM.yyyy',
    'mn-Mong-CN': 'yyyy/M/d',
    'gd-GB': 'dd/MM/yyyy',
    'en-MY': 'd/M/yyyy',
    'prs-AF': 'dd/MM/yy',
    'bn-BD': 'dd-MM-yy',
    'wo-SN': 'dd/MM/yyyy',
    'rw-RW': 'M/d/yyyy',
    'qut-GT': 'dd/MM/yyyy',
    'sah-RU': 'MM.dd.yyyy',
    'gsw-FR': 'dd/MM/yyyy',
    'co-FR': 'dd/MM/yyyy',
    'oc-FR': 'dd/MM/yyyy',
    'mi-NZ': 'dd/MM/yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'se-SE': 'yyyy-MM-dd',
    'br-FR': 'dd/MM/yyyy',
    'smn-FI': 'd.M.yyyy',
    'moh-CA': 'M/d/yyyy',
    'arn-CL': 'dd-MM-yyyy',
    'ii-CN': 'yyyy/M/d',
    'dsb-DE': 'd. M. yyyy',
    'ig-NG': 'd/M/yyyy',
    'kl-GL': 'dd-MM-yyyy',
    'lb-LU': 'dd/MM/yyyy',
    'ba-RU': 'dd.MM.yy',
    'nso-ZA': 'yyyy/MM/dd',
    'quz-BO': 'dd/MM/yyyy',
    'yo-NG': 'd/M/yyyy',
    'ha-Latn-NG': 'd/M/yyyy',
    'fil-PH': 'M/d/yyyy',
    'ps-AF': 'dd/MM/yy',
    'fy-NL': 'd-M-yyyy',
    'ne-NP': 'M/d/yyyy',
    'se-NO': 'dd.MM.yyyy',
    'iu-Cans-CA': 'd/M/yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'si-LK': 'yyyy-MM-dd',
    'sr-Cyrl-RS': 'd.M.yyyy',
    'lo-LA': 'dd/MM/yyyy',
    'km-KH': 'yyyy-MM-dd',
    'cy-GB': 'dd/MM/yyyy',
    'bo-CN': 'yyyy/M/d',
    'sms-FI': 'd.M.yyyy',
    'as-IN': 'dd-MM-yyyy',
    'ml-IN': 'dd-MM-yy',
    'en-IN': 'dd-MM-yyyy',
    'or-IN': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'tk-TM': 'dd.MM.yy',
    'bs-Latn-BA': 'd.M.yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'sr-Cyrl-ME': 'd.M.yyyy',
    'se-FI': 'd.M.yyyy',
    'zu-ZA': 'yyyy/MM/dd',
    'xh-ZA': 'yyyy/MM/dd',
    'tn-ZA': 'yyyy/MM/dd',
    'hsb-DE': 'd. M. yyyy',
    'bs-Cyrl-BA': 'd.M.yyyy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'sr-Latn-BA': 'd.M.yyyy',
    'smj-NO': 'dd.MM.yyyy',
    'rm-CH': 'dd/MM/yyyy',
    'smj-SE': 'yyyy-MM-dd',
    'quz-EC': 'dd/MM/yyyy',
    'quz-PE': 'dd/MM/yyyy',
    'hr-BA': 'd.M.yyyy.',
    'sr-Latn-ME': 'd.M.yyyy',
    'sma-SE': 'yyyy-MM-dd',
    'en-SG': 'd/M/yyyy',
    'ug-CN': 'yyyy-M-d',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'es-US': 'M/d/yyyy'
  };

  return formats[lang] || 'MM/dd/yyyy';
}

const countryList = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AP: 'ABCDEF',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas (the)',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory (the)',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands (the)',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  CO: 'Colombia',
  KM: 'Comoros (the)',
  CD: 'Congo (the Democratic Republic of the)',
  CG: 'Congo (the)',
  CK: 'Cook Islands (the)',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czechia',
  CI: "Côte d'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'Eswatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (the) [Malvinas]',
  FO: 'Faroe Islands (the)',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories (the)',
  GA: 'Gabon',
  GM: 'Gambia (the)',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See (the)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (the Democratic People's Republic of)",
  KR: 'Korea (the Republic of)',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic (the)",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands (the)',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (the Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands (the)',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands (the)',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines (the)',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  MK: 'Republic of North Macedonia',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  RW: 'Rwanda',
  RE: 'Réunion',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan (the)',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan (Province of China)',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands (the)',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates (the)',
  UK: 'United Kingdom of Great Britain and Northern Ireland (the)',
  UM: 'United States Minor Outlying Islands (the)',
  US: 'United States of America (the)',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AX: 'Åland Islands'
};

export const getLocations = () => {
  return Object.entries(countryList).map(([code, country]) => ({
    label: country,
    value: code
  }));
};

export const geos = [
  { label: 'EU', value: 'EU' },
  { label: 'NA', value: 'NA' },
  { label: 'SA', value: 'SA' },
  { label: 'APAC', value: 'APAC' },
  { label: 'LATM', value: 'LATM' },
  { label: 'AMET', value: 'AMET' }
];
