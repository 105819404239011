// Custom edit actions that renders based on whether its for
// requests or for biz line attributes
export const editActions = ({ state, businessLine, setParams, username }) => {
  // The resolve is at the top due to the requirements set by MUI
  return {
    onRowAdd: (newData) =>
      new Promise((resolve) => {
        const data = [...state, newData];

        // Fetches the attribute field name then generates an array of objects
        // related to the item. This allows to create the correct request body.
        const field = Object.keys(data[0])[0];
        const items = data.map((obj) => {
          return obj[field];
        });

        const body = { businessLine, [field]: items };
        setParams({
          alias: username,
          data: encodeURIComponent(JSON.stringify(body)),
          op: 'create',
          type: 'business_line_attribute'
        });
        resolve();
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve) => {
        const field = Object.keys(oldData)[0];
        const data = state.map((item) => {
          return oldData[field] === item[field] ? newData[field] : item[field];
        });
        const body = {
          businessLine,
          [field]: JSON.stringify(data)
        };

        setParams({
          alias: username,
          data: encodeURIComponent(JSON.stringify(body)),
          op: 'edit',
          type: 'business_line_attribute'
        });
        resolve();
      }),
    onRowDelete: (oldData) =>
      new Promise((resolve) => {
        const field = Object.keys(oldData)[0];

        const items = state.map((item) => item[field]);
        const data = items.filter((item) => item !== oldData[field]);
        const body = { businessLine };
        body[field] = JSON.stringify(data);
        setParams({
          alias: username,
          data: encodeURIComponent(JSON.stringify(body)),
          op: 'edit',
          type: 'business_line_attribute'
        });
        resolve();
      })
  };
};
