import React from 'react';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

// Move to styled components
const useStyles = makeStyles((theme) => ({
  textField: {
    width: '50%'
  },
  divider: {
    height: theme.spacing(2)
  }
}));

export default function LocationInfoGroup({ formState, handleValueChange }) {
  const classes = useStyles();
  const config = [
    { id: 'city_name', label: 'City' },
    { id: 'state_province', label: 'State/Province' },
    { id: 'county', label: 'County' },
    { id: 'latlong', label: 'Latitude, Longitude(in that format)' },
    { id: 'latitude', label: 'Latitude' },
    { id: 'longitude', label: 'Longitude' },
    { id: 'off_set', label: 'UTC Offset' },
    { id: 'timezone_name', label: 'Timezone Name' }
  ];
  const link = `https://www.google.com/search?q=${
    formState.city_name ? formState.city_name : ''
  }+${formState.county ? formState.county : ''}+${
    formState.state_province ? formState.state_province : ''
  }+${formState.country_code ? formState.country_code : ''}+lat+long`;

  const fields = Object.values(
    config.map((field) => {
      return (
        <div key={field.id}>
          {field.id === 'latlong' && (
            <>
              <div>
                {console.log(formState[field.id])}
                <Link href={link} target="_blank">
                  Click here to find the longitude and latitude!
                </Link>
                <div className={classes.divider} />
              </div>
              <TextField
                id={field.id}
                label={field.label}
                defaultValue={formState[field.id]}
                className={classes.textField}
                onChange={(event) => {
                  handleValueChange({
                    name: field.id,
                    newValue: event.target.value
                  });
                }}
                margin="normal"
              />
            </>
          )}
          {field.id !== 'latlong' && (
            <TextField
              id={field.id}
              label={field.label}
              className={classes.textField}
              value={
                formState[field.id] !== undefined
                  ? `${formState[field.id]}`
                  : ''
              }
              disabled={field.id === 'longitude' || field.id === 'latitude'}
              onChange={(event) => {
                handleValueChange({
                  name: field.id,
                  newValue: event.target.value
                });
              }}
              margin="normal"
            />
          )}
          <div className={classes.divider} />
        </div>
      );
    })
  );
  return <> {fields} </>;
}
