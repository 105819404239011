import React from 'react';
import { useNotificationState } from '../Context/NotificationContext';
import NotificationToast from './Notification';
import ConfirmAlert from './ConfirmationDialog';

function NotifWrapper() {
  const { notificationState, alertOpen, params } = useNotificationState();
  return (
    <div>
      <NotificationToast notificationState={notificationState} />
      <ConfirmAlert params={params} alertOpen={alertOpen} />
    </div>
  );
}

export default NotifWrapper;
