import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
// Move to styled components
const useStyles = makeStyles((theme) => ({
  input: { marginTop: '2%' },
  openDate: { marginTop: '25px', marginRight: '3%' }
}));

export default function DatePickerGroup({ formState, handleValueChange }) {
  const classes = useStyles();

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="begin_date"
          format="MM/dd/yyyy"
          label="Site Launch Date"
          value={formState.begin_date}
          onChange={(date) => {
            const lang =
              window.navigator.userLanguage || window.navigator.language;
            const tmp = date.toLocaleDateString(lang);
            handleValueChange({ name: 'begin_date', newValue: tmp });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          className={classes.input}
        />
        <> </>
{/*         
        <KeyboardDatePicker
          margin="normal"
          id="end_date"
          emptyLabel={/(^$|[^\d]+)/gi}
          label="Close Date"
          onError={console.log}
          format="MM/dd/yyyy"
          maxDate="12/31/3000"
          value={formState.end_date ? formState.end_date : ''}
          onChange={(date) => {
            handleValueChange({ name: 'end_date', newValue: date });
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          className={classes.openDate}
        />
         */}
      </MuiPickersUtilsProvider>
    </div>
  );
}
