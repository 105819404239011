import React, { useState, useEffect } from 'react';
import { Button } from '@amzn/stencil-react-components/button';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import { useNotificationDispatch } from '../Context/NotificationContext';
import { useEmpInfoState } from '../Context/EmpInfoContext';
import Loader from '../common/Loader';
// Move to styled components
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  divider: {
    height: theme.spacing(2)
  },

  active: { marginTop: '2%', marginLeft: '13.5%' },
  loader: {
    flexGrow: 1,
    display: 'absolute',
    marginTop: '15%',
    marginLeft: '45%'
  },
  textField: {
    width: '50%'
  },
  submit: { marginLeft: 'auto', marginRight: '2%' }
}));

// Builds out a view to handle creation and editing of business lines
export default function BusinessLineForm({
  toggleModal,
  validCostCenters,
  allBusinessLines,
  isEdit,
  isRequest,
  defaultData
}) {
  const classes = useStyles();
  const [state, setState] = useState();
  const { setParams, setNotificationState } = useNotificationDispatch();

  const {
    empInfo: { sub: username }
  } = useEmpInfoState();
  useEffect(() => {
    defaultData.businessLine
      ? setState({
          businessLine: defaultData.businessLine,
          ...state,
          subdivisions: defaultData.subdivisions,
          threshold: defaultData.threshold,
          cost_center: defaultData.costcenters
            ? defaultData.costcenters.map(({ cost_center }) => cost_center)
            : null,
          oldBusinessLine: defaultData.businessLine
        })
      : setState({ ...defaultData, ...state });

    // only want to run this on start up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!validCostCenters) return <Loader />;

  const options = validCostCenters.map((center) => ({
    label: center,
    value: center
  }));

  const changeHandler = ({ name, newValue }) => {
    setState({ ...state, [name]: newValue });
  };

  return (
    <div className={classes.root}>
      <h4>Business Line Form</h4>
      <div>
        <TextField
          id="businessLine"
          label="Business Line"
          className={classes.textField}
          value={state ? state.businessLine : null}
          onChange={(event) => {
            changeHandler({
              name: 'businessLine',
              newValue: event.target.value
            });
          }}
          margin="normal"
        />
        <div className={classes.divider} />
        <span style={{ marginBottom: '1%', marginLeft: '0.5%' }}>
          Cost Center
        </span>
        <div style={{ width: '50%', marginTop: '1%' }}>
          <Select
            placeholder="Cost Center"
            options={options}
            isMulti
            value={
              state && state.cost_center
                ? state.cost_center.map((item) => ({
                    label: item,
                    value: item
                  }))
                : null
            }
            onChange={(value) =>
              changeHandler({
                name: 'cost_center',
                newValue: value ? value.map((item) => item.value) : null
              })
            }
          />
        </div>
        <div className={classes.divider} />
        <TextField
          id="threshold"
          label="Threshold"
          className={classes.textField}
          value={state ? state.threshold : null}
          onChange={(event) => {
            changeHandler({
              name: 'threshold',
              newValue: event.target.value
            });
          }}
          margin="normal"
        />
        <div className={classes.divider} />

        <Button
          variant="contained"
          primary
          aria-label="submit"
          className={classes.submit}
          onClick={() => {
            if (
              state.businessLine &&
              state.businessLine.length > 0 &&
              state.cost_center &&
              !isNaN(state.threshold) &&
              (isEdit || !allBusinessLines.has(state.businessLine)) &&
              state.cost_center.length > 0
            ) {
              if (!isRequest) {
                setParams({
                  alias: username,
                  data: encodeURIComponent(
                    JSON.stringify({
                      ...state,
                      subdivisions: state.subdivisions
                        ? state.subdivisions.map(
                            (subdiv) => subdiv.subdivisions
                          )
                        : []
                    })
                  ),
                  op: defaultData.businessLine ? 'edit' : 'create',
                  type: 'business_line'
                });
              } else {
                const params = {
                  data: encodeURIComponent(
                    JSON.stringify({
                      requestAlias: isRequest.requestAlias,
                      requestTime: isRequest.requestTime,
                      requestData: JSON.stringify({
                        ...state,
                        subdivisions: state.subdivisions
                          ? state.subdivisions.map(
                              (subdiv) => subdiv.subdivisions
                            )
                          : []
                      })
                    })
                  ),
                  operation: 'edit',
                  type: 'request'
                };
                setParams(params);
              }
              toggleModal();
            } else if (isNaN(state.threshold)) {
              setNotificationState({
                open: true,
                message: 'Threshold must be numeric!'
              });
            } else if (!isEdit && allBusinessLines.has(state.businessLine)) {
              setNotificationState({
                open: true,
                message: 'Cannot create this business line already exists!'
              });
            } else {
              setNotificationState({
                open: true,
                message:
                  'Cannot create you need to fill out business line name and/or cost centers!'
              });
            }
          }}
        >
          Submit Request
        </Button>
      </div>
    </div>
  );
}
