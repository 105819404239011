import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import Loader from '../common/Loader';
import DataTable from '../common/ChildTable';
import LocationFormModal from '../Location/LocationFormModal';
import { useEmpInfoState } from '../Context/EmpInfoContext';
import { parseBusinessLineResponse, fetchClient } from '../Utils/globalUtils';
import { ApprovalButtons } from './ApprovalButtons';
import { makeStyles } from '@material-ui/core/styles';
import BusinessLineModal from '../BusinessLine/BusinessLineEditModal';

import {
  useNotificationDispatch,
  useNotificationState
} from '../Context/NotificationContext';
import MaterialTable from 'material-table';
import { tableIcons } from '../Utils/tableUtils';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
//Function to handle sending user approval of request
const useStyles = makeStyles((theme) => ({
  tableWrapper: { padding: '2%' }
}));

const getColumns = ({
  rowData,
  user,
  groups,
  isSuperUser,
  setNotificationState,
  jwtToken
}) => {
  return (rowData.requestAlias !== user || isSuperUser) &&
    groups.some((group) => group === 'blip-approvers') &&
    rowData.lifecyclePhase === 'PENDING_APPROVAL' ? (
    <ApprovalButtons
      rowData={rowData}
      setNotificationState={setNotificationState}
      jwtToken={jwtToken}
    />
  ) : rowData.requestAlias === user &&
    rowData.lifecyclePhase === 'PENDING_APPROVAL' ? (
    <div>
      <Typography>Cannot approve your own request!</Typography>{' '}
    </div>
  ) : !groups.some((group) => group === 'blip-approvers') ? (
    <div>
      <Typography>You do not have permissions to approve!</Typography>{' '}
    </div>
  ) : (
    <div>
      <Typography>Already {rowData.lifecyclePhase}!</Typography>{' '}
    </div>
  );
};
const dataResolver = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] =
      data[key] === 't' || data[key] === 'f' ? data[key] === 't' : data[key];
    if (key === 'global_region') {
      acc['geo'] = data[key];
    } else if (key === 'id') {
      acc['location_id'] = data[key];
    }
    return acc;
  }, {});
};

export default function RequestTable() {
  const [requestObj, setRequestObj] = useState({});
  const [modalOpen, toggleModalOpen] = useState(false);
  const toggleModal = useCallback(() => {
    toggleModalOpen(!modalOpen);
  }, [modalOpen]);
  const [businessLineModalOpen, toggleBusinessLineModalOpen] = useState(false);
  const toggleBusinessLineModal = useCallback(() => {
    toggleBusinessLineModalOpen(!businessLineModalOpen);
  }, [businessLineModalOpen]);
  const [businessLineState, setBusinessLineState] = useState();
  const [formState, setFormState] = useState();
  const [currBusinessLine, setCurBusinessLine] = useState();
  // States for storing requests and storing employee info context
  const [requests, setRequests] = useState({
    loading: true,
    requestData: null
  });
  const {
    empInfo,
    isSuperUser,
    authState: { session }
  } = useEmpInfoState();
  const { setNotificationState, toggleAlert } = useNotificationDispatch();
  const { sub: user, groups, allowEdit } = empInfo;
  const { setParams } = useNotificationDispatch();
  const { notificationState } = useNotificationState();

  const detailPane = (rawRowData) => {
    const data = JSON.parse(rawRowData.requestData);
    const rowData = Object.keys(data).reduce(
      (acc, key) => {
        if (typeof data[key] === 'boolean') {
          acc[key] = data[key] ? 't' : 'f';
        }
        return acc;
      },
      { ...data }
    );
    const keys = Object.keys(rowData);
    const columns = keys.map((key) => {
      return { title: key, field: key };
    });
    const actions = [
      {
        icon: () => <Edit />,
        tooltip: 'Edit Request',
        disabled: !(
          rawRowData.lifecyclePhase !== 'APPROVED' &&
          rawRowData.requestAlias === user &&
          allowEdit
        ),
        onClick: (_event, rowData) => {
          if (rowData.derived_location_code) {
            setFormState(dataResolver(rowData));
            setRequestObj({
              requestAlias: rawRowData.requestAlias,
              requestTime: rawRowData.requestTime
            });
            toggleModal();
          } else {
            setRequestObj({
              requestAlias: rawRowData.requestAlias,
              requestTime: rawRowData.requestTime
            });
            setCurBusinessLine({
              businessLine: rowData.businessLine,
              threshold: rowData.threshold
            });
            toggleBusinessLineModal();
          }
        }
      },
      {
        icon: () => <DeleteOutline />,
        tooltip: 'Delete Request',
        disabled: !(
          rawRowData.lifecyclePhase !== 'APPROVED' &&
          rawRowData.requestAlias === user &&
          allowEdit
        ),
        onClick: (event, rowData) =>
          new Promise((resolve) => {
            setParams({
              data: encodeURIComponent(
                JSON.stringify({
                  requestAlias: rawRowData.requestAlias,
                  requestTime: rawRowData.requestTime,
                  locationCode: rowData.derived_location_code
                })
              ),
              operation: 'delete',
              type: 'request'
            });
            setRequests({ ...requests, loading: true });
            resolve();
          })
      }
    ];

    const reducedData = Object.entries(rowData).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value ? value.toString() : undefined };
    }, {});
    return (
      <MaterialTable
        icons={tableIcons}
        title={'Request Payload'}
        columns={columns}
        actions={actions}
        data={[reducedData]}
        options={{
          paging:false,
          search:false
        }}
      />
    );
  };

  const classes = useStyles();
  useEffect(() => {
    async function execute() {
      // Fetch the business lines and their associated attributes
      const businessLineBody = await fetchClient({
        url: 'getBusinessLine',
        jwtToken: session.idToken.jwtToken
      });

      // Parse the business line response and convert it into a format that is readable by the
      // material table
      if (businessLineBody !== undefined) {
        const { attributes } = parseBusinessLineResponse({
          businessLineBody,
          fieldNameBline: 'businessLine',
          fieldNameSub: 'subdivisions'
        });
        // Set up the definitions for hte columns and the associated data
        // Also setting up a custom redner for editing the assoicated attributes
        setBusinessLineState({
          loading: false,
          businessLineData: {
            attributes: attributes
          }
        });
      }
    }
    execute();
  }, [classes, session.idToken.jwtToken, toggleModal]);

  // Fetch request data and do some formatting before
  // feeding into tables
  useEffect(() => {
    async function execute() {
      const requestJson = await fetchClient({
        url: 'getRequests',
        jwtToken: session.idToken.jwtToken,
        params: { alias: user }
      });
      if (requestJson !== undefined) {
        const keys =
          requestJson.length > 0
            ? Object.keys(requestJson[0]).filter((e) => {
                return e !== 'taskToken';
              })
            : [
                { title: 'lifecyclePhase', field: 'lifecyclePhase' },
                { title: 'requestAlias', field: 'requestAlias' },
                { title: 'requestTime', field: 'requestTime' },
                { title: 'requestType', field: 'requestType' },
                { title: 'requestOperation', field: 'requestOperation' },
                { title: 'requestData', field: 'requestData' }
              ];
        const lang = window.navigator.userLanguage || window.navigator.language;

        const columns =
          requestJson.length > 0
            ? keys.map((key) => {
                if (key === 'requestTime')
                  return {
                    title: key,
                    field: key,
                    render: (rowData) => (
                      <div>
                        {new Date(rowData.requestTime).toLocaleString(lang)}
                      </div>
                    )
                  };
                else return { title: key, field: key };
              })
            : keys;
        // Build out an object to render buttons to approve/deny request
        const tempObj = {
          title: 'Approval',
          field: 'taskToken',
          render: (rowData) =>
            getColumns({
              rowData,
              groups,
              user,
              isSuperUser,
              setNotificationState,
              jwtToken: session.idToken.jwtToken
            })
        };
        const columnsCustomRender = [...columns, tempObj];
        setRequests({
          loading: false,
          requestData: { columns: columnsCustomRender, data: requestJson }
        });
      }
    }
    execute();
  }, [
    user,
    isSuperUser,
    setNotificationState,
    requests.loading,
    notificationState,
    session.idToken.jwtToken,
    groups
  ]);
  const csvParser = (allData) => (columns, data) => {
    const replacer = (key, value) =>
      value === null || value === undefined ? '' : value; // specify how you want to handle null values here
    if (allData.length === 0) {
      setNotificationState({
        open: true,
        message: 'No requests seeking approval'
      });
      return;
    }
    const header = allData.reduce((acc, datum) => {
      return acc.length > Object.keys(datum).length ? acc : Object.keys(datum);
    }, []);
    let csv = allData
      .map((row) => {
        if (row.businessLine && row.oldBusinessLine) {
          return undefined;
        }
        return header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',');
      })
      .filter((item) => !!item);
    csv.unshift(header.join(','));
    csv = 'data:text/csv;charset=utf-8,' + csv.join('\r\n');
    var encodedUri = encodeURI(csv);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'requests.csv');
    document.body.appendChild(link); // Required for FF

    link.click(); // This will
    return csv;
  };
  //Conditional render to have a spinner until request api has given use the request info
  if (requests.loading || !businessLineState) {
    return <Loader />;
  }

  return (
    <div className={classes.tableWrapper}>
      <BusinessLineModal
        modalOpen={businessLineModalOpen}
        toggleModal={toggleBusinessLineModal}
        businessLine={currBusinessLine}
        attributes={businessLineState.businessLineData.attributes}
        isEdit={true}
        isRequest={requestObj}
      />
      <LocationFormModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        formState={formState}
        setFormState={setFormState}
        toggleAlert={toggleAlert}
        isRequest={requestObj}
      />
      <DataTable
        columns={requests.requestData.columns}
        data={requests.requestData.data}
        title={'Requests'}
        setRequests={setRequests}
        requests={requests}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={detailPane}
        csvParser={csvParser}
        components={{ Pagination: [] }}
      />
    </div>
  );
}