import React, {
  useRef,
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback
} from 'react';

const NotificationContext = createContext({});
const NotificationDispatchContext = createContext({});

function NotificationProvider({ children }) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [notificationState, setNotificationState] = useState({
    open: false,
    message: 'nothing here'
  });
  const [params, setParams] = useState();
  const firstUpdate = useRef(true);

  const toggleAlert = useCallback(() => {
    setAlertOpen(alertOpen => !alertOpen);
  }, []);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    toggleAlert();
  }, [params, toggleAlert]);

  return (
    <NotificationContext.Provider
      value={{ alertOpen, notificationState, params }}
    >
      <NotificationDispatchContext.Provider
        value={{ toggleAlert, setNotificationState, setParams }}
      >
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationContext.Provider>
  );
}

function useNotificationState() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationState must be used within a NotificationProvider'
    );
  }
  return context;
}

function useNotificationDispatch() {
  const context = useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationDispatch must be used within a useNotificationProvider'
    );
  }
  return context;
}

export { NotificationProvider, useNotificationState, useNotificationDispatch };
