import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

const dataResolver = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (key === 'location_id') {
      data.id = data[key];
    } else if (key === 'geo') {
      data.global_region = data[key];
    } else if (key === 'latitude') {
      acc.latlong = `${data.latitude},${data.longitude}`;
    }
    acc[key] =
      data[key] === 't' || data[key] === 'f' ? data[key] === 't' : data[key];
    return acc;
  }, {});
};
export default function ActionButtons({
  props,
  handleMutation,
  username,
  allowEdit
}) {
  return (
    <div>
      <IconButton
        disabled={
          !allowEdit ||
          (username !== undefined && !(props.data.requestAlias === username))
        }
        onClick={() => {
          handleMutation({
            currentState: dataResolver(props.data)
          });
        }}
      >
        <Edit />
      </IconButton>
      {/* Temporarily removing because we do not want to remove sites as of yet */}
      {/* <IconButton
        disabled={
          username === undefined && props.data.requestAlias === username
        }
        onClick={() => {
          const params = {
            data: encodeURIComponent(
              JSON.stringify({
                requestAlias: props.data.requestAlias,
                requestTime: props.data.requestTime
              })
            ),
            operation: 'delete',
            type: 'request'
          };
          setParams(params);
        }}
      >
        <DeleteOutline />
      </IconButton> */}
    </div>
  );
}
