import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Button } from '@amzn/stencil-react-components/button';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Loader from '../common/Loader';
import { makeStyles } from '@material-ui/core/styles';
import AutoSuggestSelect, { SuggestSelect } from './Form/AutoSuggestSelect';
import { useNotificationDispatch } from '../Context/NotificationContext';
import {
  generateParams,
  parseBusinessLineResponse,
  fetchClient,
  getLocations,
  geos
} from '../Utils/globalUtils';
import CheckBoxGroup from './Form/CheckBoxGroup';
import DescriptionFieldGroup from './Form/DescriptionFieldGroup';
import DatePickerGroup from './Form/DatePickerGroup';
import LocationInfoGroup from './Form/LocationInfoGroup';
import { Card, CardContent } from '@material-ui/core';
import { useEmpInfoState } from '../Context/EmpInfoContext';
import { isEqual } from 'lodash';

// Move to styled components
const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    flexGrow: 1
  },

  divider: {
    height: theme.spacing(2)
  },

  active: { marginTop: '2%', marginLeft: '13.5%' },
  loader: {
    flexGrow: 1,
    display: 'absolute',
    marginTop: '15%',
    marginLeft: '45%'
  },
  textField: {
    width: '50%'
  },
  submit: { marginLeft: 'auto', marginRight: '2%' }
}));

export default function LocationForm({
  formState,
  codes,
  orgLookup,
  toggleModal,
  isRequest
}) {
  const classes = useStyles();
  const [state, setState] = useState({ loading: true, locationFormData: null });
  const [cache, setCache] = useState(formState);
  const [err, setErr] = useState();
  const [card, setCard] = useState();
  const { setParams, setNotificationState } = useNotificationDispatch();
  let businessLineChange = useRef(false);
  const {
    authState: { session }
  } = useEmpInfoState();

  const handleValueChange = useCallback(
    async ({ name, newValue, isNew }) => {
      if (cache[name] !== newValue) {
        if (name === 'location_id') {
          setCache({
            ...cache,
            id: newValue,
            [name]: newValue
          });
          return;
        }
        if (name === 'geo') {
          setCache({
            ...cache,
            global_region: newValue,
            [name]: newValue
          });
          return;
        }
        if (name === 'business_line') {
          businessLineChange.current = true;
        }
        if (name === 'location_code') {
          setCache({
            ...cache,
            [name]: newValue,
            derived_location_code: newValue
          });
          if (isNew) return;
          const locationInfo = await fetchClient({
            url: 'fetchLocationInfo',
            params: { locationCode: newValue },
            jwtToken: session.idToken.jwtToken
          });
          const { location_descr, location_id } = locationInfo;
          setCache({
            ...cache,
            [name]: newValue,
            location_description: location_descr || cache.location_description,
            derived_location_description:
              location_descr || cache.derived_location_description,
            location_id: location_id || cache.location_id,
            id: location_id || cache.location_id
          });

          return;
        } else if (name === 'latlong') {
          // Google returns the latlon in a weird format so we take that
          // and convert it to a floating point representation

          const longSign = newValue.includes('W') ? -1 : 1;
          const latSign = newValue.includes('S') ? -1 : 1;
          const latlon = newValue
            .replace('°', '')
            .replace('°', '')
            .replace('W', '')
            .replace('E', '')
            .replace('S', '')
            .replace('N', '')
            .split(',');

          const latitude = Number(latlon[0] * latSign);
          const longitude = Number(latlon[1] * longSign);
          if (!isNaN(latitude) && !isNaN(longitude)) {
            const { tzOffset, tz } = await fetchClient({
              url: 'getTimeZone',
              params: { latitude, longitude },
              jwtToken: session.idToken.jwtToken
            });
            const adjustedOffset = Math.floor(tzOffset / 60);
            setCache({
              ...cache,
              latitude,
              longitude,
              off_set: adjustedOffset,
              timezone_name: tz,
              [name]: newValue
            });
          } else {
            setCache({
              ...cache,
              [name]: newValue
            });
          }
          return;
        } else {
          setCache({
            ...cache,
            [name]: newValue
          });
        }
      }
    },
    [cache, session.idToken.jwtToken]
  );

  useEffect(() => {
    if (businessLineChange.current && cache.business_line) {
      handleValueChange({
        name: 'subdivision',
        newValue: undefined
      });
      businessLineChange.current = false;
    }
  }, [cache.business_line, handleValueChange]);

  const firstRender = useRef(false);
  useEffect(() => {
    async function execute() {
      const businessLineBody = await fetchClient({
        url: 'getBusinessLine',
        jwtToken: session.idToken.jwtToken
      });
      let locationCodesBody = await fetchClient({
        url: 'getLocationCodes',
        jwtToken: session.idToken.jwtToken
      });
      locationCodesBody = [
        ...locationCodesBody.reduce((acc, obj) => {
          acc.add(obj.location_code);
          return acc;
        }, new Set())
      ];

      if (businessLineBody !== undefined && locationCodesBody !== undefined) {
        const { data, attributes } = parseBusinessLineResponse({
          businessLineBody: businessLineBody,
          fieldNameBline: 'label',
          fieldNameSub: 'label',
          fieldNameCost: 'label'
        });
        const formattedLocationCode = locationCodesBody.map((i) => ({
          label: i
        }));
        setState({
          loading: false,
          locationFormData: {
            data: data,
            locationCodes: formattedLocationCode,
            attributes: attributes
          }
        });
        firstRender.current = true;
      }
    }
    if (!firstRender.current) execute();
  }, [session.idToken.jwtToken]);
  if (state.loading || cache === undefined) {
    return <Loader />;
  }
  return (
    <div className={classes.root}>
      <div
        ref={(node) => {
          setCard(node);
        }}
      >
        <Card
          style={{
            backgroundColor: '#FA8072',
            width: '50%',
            display: err ? 'inherit' : 'none'
          }}
        >
          <CardContent> {err} </CardContent>{' '}
        </Card>
      </div>

      <div className={classes.divider} />
      <form autoComplete="new-password">
        <TextField
          value={cache.location_id || ''}
          onChange={(event) =>
            handleValueChange({
              name: 'location_id',
              newValue: event.target.value
            })
          }
          id="id"
          label="Location Id"
        />
        <div className={classes.divider} />
        <AutoSuggestSelect
          suggestionData={state.locationFormData.locationCodes}
          formState={cache}
          handleValueChange={handleValueChange}
          fieldName="location_code"
          label="Location Code (type to search or select)"
          value="Ex: ORD2"
        />
        <div className={classes.divider} />
        <AutoSuggestSelect
          suggestionData={[
            { label: 'GCF', value: 'GCF' },
            { label: 'AD', value: 'AD' },
            { label: 'WWGS', value: 'WWGS' },
            { label: 'CS', value: 'CS' },
            {label: 'WW Amazon Stores', value: 'WW Amazon Stores'}
          ]}
          formState={cache}
          handleValueChange={handleValueChange}
          fieldName="org"
          label="Organization"
        />
        <AutoSuggestSelect
          suggestionData={state.locationFormData.data}
          formState={cache}
          handleValueChange={handleValueChange}
          fieldName="business_line"
          label="Business Line (type to search or select)"
          placeholder="Ex: AMXL"
        />
        {(cache.business_line!=='Ex: AMZL') ? (
          <div>
            <SuggestSelect
              suggestionData={
                state.locationFormData.attributes[cache.business_line]
                  .subdivisions
              }
              formState={cache}
              businessLine={cache.business_line}
              handleValueChange={handleValueChange}
              fieldName="subdivision"
              label="Subdivision"
            />
          </div>
        ) : (
          <div>
            <TextField
              disabled
              id="subdivision"
              label="subdivision"
              className={classes.textField}
              value="Choose business Line"
              margin="normal"
            />
          </div>
        )}

        <CheckBoxGroup
          formState={cache}
          handleValueChange={handleValueChange}
        />
        <div className={classes.divider} />

        {cache.is_colocated && (
          <TextField
            value={cache.derived_location_code}
            onChange={(event) =>
              handleValueChange({
                name: 'derived_location_code',
                newValue: event.target.value
              })
            }
            id="derived_location_code"
            label="Derived Code"
          />
        )}
        <DescriptionFieldGroup
          formState={cache}
          handleValueChange={handleValueChange}
        />
        <div className={classes.divider} />

        <div className={classes.divider} />
        <DatePickerGroup
          formState={cache}
          handleValueChange={handleValueChange}
        />

        <AutoSuggestSelect
          suggestionData={geos}
          formState={cache}
          handleValueChange={handleValueChange}
          fieldName="geo"
          label="Global Region"
        />
        <AutoSuggestSelect
          suggestionData={getLocations()}
          formState={cache}
          handleValueChange={handleValueChange}
          fieldName="country_code"
          label="Country Code"
        />
        <LocationInfoGroup
          formState={cache}
          handleValueChange={handleValueChange}
        />
      </form>

      <div style={{ paddingBottom: 16 }}>
        <Button
          variant="contained"
          primary
          aria-label="submit"
          className={classes.submit}
          onClick={() => {
            const type = isRequest ? 'request' : 'location';
            const { latlong, ...newCache } = cache;
            const isValid = Object.keys(newCache).reduce((acc, key) => {
              if (newCache[key] === undefined || newCache[key] === 'Ex: ORD2' || 
              newCache[key] === 'Ex: AMZL' ) {
                if (
                  key === 'old_derived_code' ||
                  (!newCache.is_colocated &&
                    (key === 'derived_location_code' ||
                      key === 'parent_business_line' ||
                      key === 'derived_location_description'))
                ) {
                  return acc;
                }
                acc.push(key);
              }
              if (key === 'location_id' && isNaN(newCache[key])) {
                acc.push(key);
              }
              return acc;
            }, []);
            if (
              isValid.length === 0 && 
              !isEqual(formState, newCache) &&
              (!newCache.is_colocated ||
                (newCache.is_colocated &&
                  newCache.business_line !== newCache.parent_business_line))
            ) {
              delete newCache.geo;
              delete newCache.location_id;
              if (!isRequest) {
                generateParams({
                  data: newCache,
                  setParams: setParams,
                  type
                });
              } else {
                delete newCache.tableData;
                const params = {
                  data: encodeURIComponent(
                    JSON.stringify({
                      requestAlias: isRequest.requestAlias,
                      requestTime: isRequest.requestTime,
                      requestData: JSON.stringify(newCache)
                    })
                  ),
                  operation: 'edit',
                  type: 'request'
                };
                setParams(params);
              }
              toggleModal();
            } else if (isEqual(formState, newCache) ) {
              setNotificationState({
                open: true,
                message: 'An error occured, scroll up to learn more'
              });
              setErr('Your request has no changes!');
              card.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            } else if (isValid.length > 0 ) {

              setNotificationState({
                open: true,
                message: 'An error occured, scroll up to learn more'
              });
              setErr(
                `You are missing or have issues with: ${isValid.join(', ')}`
              );
              card.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            } else if (
              newCache.is_colocated &&
              newCache.business_line === newCache.parent_business_line
            ) {
              setNotificationState({
                open: true,
                message: 'An error occured, scroll up to learn more'
              });
              setErr(
                'You are trying to create a colocated site with the same business line as the parent!'
              );
              card.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            } else {
              setNotificationState({
                open: true,
                message: 'Something went wrong!'
              });
            }
          }}
        >
          Submit Request
        </Button>
        <br />
      </div>
    </div>
  );
}
