import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useNotificationDispatch } from '../Context/NotificationContext';

export default function Notification({ notificationState }) {
  const { setNotificationState } = useNotificationDispatch();
  const handleClose = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: notificationState.message
    });
  };
  const vertical = 'top';
  const horizontal = 'left';
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key="top,left"
        disableWindowBlurListener={true}
        autoHideDuration={3600}
        open={notificationState.open}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        style={{ color: 'green' }}
        message={<span id="message-id">{`${notificationState.message}`}</span>}
      />
    </div>
  );
}
