import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CreatableSelect from 'react-windowed-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

/**
 * This entire class is just using the defaults for autosuggest and only needs the suggestion
 * data. Ideally we would never want to modify any part of this
 */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '50%'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  }
}));

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function Control(props) {
  const {
    children,
    innerProps = {},
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;
  const { onMouseMove, onMouseOver, ...newInnerProps } = innerProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...newInnerProps
        }
      }}
      {...{
        ...TextFieldProps
      }}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

function Option(props) {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps || {};
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...rest}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

function Placeholder(props) {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps || {};
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...rest}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

function Menu(props) {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps || {};
  return (
    <Paper square className={props.selectProps.classes.paper} {...rest}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};

const components = {
  Control,
  Menu,
  MultiValue,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export function SuggestSelect({
  suggestionData,
  formState,
  businessLine,
  handleValueChange,
  fieldName,
  label
}) {
  const [searchVal, setSearchVal] = useState();
  const [suggestions, setSuggestions] = useState();
  useEffect(() => {
    const suggestion =
      fieldName === 'country_code'
        ? suggestionData
        : suggestionData.map((suggestion) => ({
            value: suggestion.label,
            label: suggestion.label
          }));
    setSuggestions(suggestion);
  }, [fieldName, suggestionData]);

  function NoOptionsMessage(props) {
    const tmp = () => {
      setSuggestions([
        ...suggestions,
        { value: searchVal, label: searchVal, isNew: true }
      ]);
    };
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {fieldName !== 'location_code' && fieldName !== 'subdivision' ? (
          props.children
        ) : (
          <Button onClick={tmp}>Add new site</Button>
        )}
      </Typography>
    );
  }

  NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired
  };

  const classes = useStyles();
  const theme = useTheme();
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  };
  function handleChange(value) {
    handleValueChange({
      name: fieldName,
      newValue: value.value,
      isNew: value.isNew
    });
  }
  return (
    <div className={classes.root}>
      <CreatableSelect
        classes={classes}
        styles={selectStyles}
        inputId={fieldName}
        TextFieldProps={{
          label,
          InputLabelProps: {
            htmlFor: 'react-select-single',
            shrink: true
          },
          placeholder: 'Search'
        }}
        options={suggestions}
        components={{ ...components, NoOptionsMessage }}
        value={{
          label: formState[fieldName],
          value: formState[fieldName]
        }}
        onChange={handleChange}
        onInputChange={(value) => setSearchVal(value)}
      />
      <div className={classes.divider} />
    </div>
  );
}

// Since the other props are unlikely to change we only want to check
// the variable containing our value. By memoizing this function we
// can prevent constant rerenders that causes considerable input lag
function areEqual(prevProps, newProps) {
  if (!newProps.businessLine) {
    return prevProps.formState === newProps.formState;
  } else {
    return (
      prevProps.businessLine === newProps.businessLine &&
      prevProps.formState === newProps.formState
    );
  }
}

export default React.memo(SuggestSelect, areEqual);
