import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableActions } from 'material-table';
import ActionButtons from '../Request/ActionButtons';
import { useNotificationDispatch } from '../Context/NotificationContext';
import { useEmpInfoState } from '../Context/EmpInfoContext';
import { tableIcons } from '../Utils/tableUtils';
import { editActions } from './ChildTableUtil';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  requestdata: {
    maxWidth: '10vw !important',
    wordWrap: 'break-word !important'
  },
  tableWrapper: { flex: 1 },
  buttonWrapper: {
    overflow: 'hidden'
  }
}));
const constructRequestDataDisplay = (data) => {
  return <Typography>Click this row to view!</Typography>;
};
// Child table used to render the attribute tables for business line
// also used to render the requests table
export default function ChildTable({
  columns,
  data,
  title,
  editable,
  businessLine,
  onRowClick,
  csvParser,
  detailPanel
}) {
  const [formState, setFormState] = useState({});
  const [modalOpen, toggleModalOpen] = useState(false);
  const { setParams, toggleAlert } = useNotificationDispatch();
  const customFieldMap = {
    Requests: {
      field: 'requestData',
      component: (rowData) => {
        return (
          <div className={classes.requestdata}>
            {constructRequestDataDisplay(rowData.requestData)}
          </div>
        );
      }
    }
  };
  const {
    empInfo: { sub: username, allowEdit }
  } = useEmpInfoState();
  const toggleModal = useCallback(() => {
    toggleModalOpen(!modalOpen);
  }, [modalOpen, toggleModalOpen]);
  const classes = useStyles();

  const handleMutation = ({ currentState }) => {
    const data = JSON.parse(currentState.requestData);

    setFormState({
      ...formState,
      ...data,
      old_derived_code: data.derived_location_code,
      requestTime: currentState.requestTime
    });
    toggleModal();
  };
  const customColumns = Object.values(columns);

  // Certain tables requre custom renders for columns
  // This will check our custom columns map to see if the current table
  // needs custom renders.
  if (Object.keys(customFieldMap).includes(title)) {
    const requestDataIdx = customColumns.findIndex((element) => {
      return element.field === customFieldMap[title].field;
    });
    customColumns[requestDataIdx]['render'] = (rowData) =>
      customFieldMap[title].component(rowData);
  }

  const editableAttr = editActions({
    username,
    state: data,
    businessLine,
    setParams,
    toggleAlert
  });
  return (
    <div className={classes.tableWrapper}>
      <MaterialTable
        title={title}
        icons={tableIcons}
        columns={customColumns}
        data={Object.values(data)}
        options={title === 'Requests'?{
          exportButton: !!csvParser,
          exportCsv: csvParser
            ? csvParser(
                data
                  .filter((d) => d.requestAlias !== username)
                  .map((d) => JSON.parse(d.requestData))
              )
            : undefined,
            pageSize: 50,
            pageSizeOptions:[5, 10, 20, 30, 40, 50]
        }:
        undefined
        }
        detailPanel={detailPanel}
        onRowClick={onRowClick}
        editable={title === 'Requests' ? editable : editableAttr}
        components={{
          Actions: (props) =>
            title === 'Requests' &&
            props.data !== undefined &&
            props.data.requestType === 'location' ? (
              <div className={classes.buttonWrapper}>
                <ActionButtons
                  username={username}
                  handleMutation={handleMutation}
                  props={props}
                  allowEdit={allowEdit}
                />
              </div>
            ) : (
              <MTableActions {...props} />
            )
        }}
      />
    </div>
  );
}