import React, { useState, useCallback, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Button } from '@amzn/stencil-react-components/button';
import { makeStyles } from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';
import Edit from '@material-ui/icons/Edit';
import Loader from '../common/Loader';
import { tableIcons } from '../Utils/tableUtils';
import SimpleModal from './BusinessLineEditModal';
import { useNotificationDispatch } from '../Context/NotificationContext';
import { useEmpInfoState } from '../Context/EmpInfoContext';
import { parseBusinessLineResponse, fetchClient } from '../Utils/globalUtils';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  businessLineTable: { padding: '1.5%' },
  actionWrapper: { paddingLeft: '50px' },
  action: { marginLeft: 'auto' },
  editIcon: { marginRight: '2%' },
  addIcon: { marginRight: '2%' }
}));

export default function BusinessLineTable() {
  const [modalOpen, toggleModalOpen] = useState(false);
  const [currBusinessLine, setCurrBusinessLine] = useState();
  const [isEdit, setIsEdit] = useState();
  const [state, setState] = useState({ loading: true, businessLineData: null });
  const { setParams, toggleAlert } = useNotificationDispatch();
  const {
    empInfo: { sub: username, allowEdit },
    authState: { session }
  } = useEmpInfoState();

  // Seperate definition for the mutator actions in the table
  // The resolve is at the top due to the requirements set by MUI
  const editableActions = ({ setParams, username }) => {
    return {
      isDeletable: (rowData) => rowData.businessLine === undefined,
      onRowAdd: (newData) =>
        new Promise((resolve) => {
          resolve();
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve) => {
          resolve();
        })
    };
  };

  const toggleModal = useCallback(() => {
    toggleModalOpen(!modalOpen);
  }, [modalOpen]);
  const classes = useStyles();

  useEffect(() => {
    async function execute() {
      // Fetch the business lines and their associated attributes
      const businessLineBody = await fetchClient({
        url: 'getBusinessLine',
        jwtToken: session.idToken.jwtToken
      });

      // Parse the business line response and convert it into a format that is readable by the
      // material table
      if (businessLineBody !== undefined) {
        const {
          data: businessLineData,
          attributes
        } = parseBusinessLineResponse({
          businessLineBody,
          fieldNameBline: 'businessLine',
          fieldNameSub: 'subdivisions'
        });
        const data = businessLineData.map((datum) => ({
          businessLine: datum.businessLine,
          costCenter: attributes[datum.businessLine].costcenters
            .map((item) => item.cost_center)
            .join(','),
          threshold: Number(attributes[datum.businessLine].threshold)
        }));
        // Set up the definitions for hte columns and the associated data
        // Also setting up a custom redner for editing the assoicated attributes
        const datalen=data.length;
        console.log(datalen);
        setState({
          loading: false,
          businessLineData: {
            columns: [
              {
                title: 'Business Line',
                field: 'businessLine'
              },
              { title: 'Cost Centers', field: 'costCenter' },
              { title: 'Threshold', field: 'threshold' }
            ],
            data,
            attributes: attributes,
            datalen:datalen
          }
        });
      }
    }
    execute();
  }, [classes, session.idToken.jwtToken, toggleModal]);
  if (state.loading) {
    return <Loader />;
  }
  return (
    <div className={classes.businessLineTable}>
      <SimpleModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        businessLine={currBusinessLine}
        attributes={state.businessLineData.attributes}
        isEdit={isEdit}
      />
      <MaterialTable
        title="Business Lines"
        icons={tableIcons}
        columns={Object.values(state.businessLineData.columns)}
        data={Object.values(state.businessLineData.data)}
        editable={editableActions({ username, setParams, toggleAlert })}
        options={{
          pageSize: state.businessLineData.datalen,
          pageSizeOptions:[state.businessLineData.datalen]
        }}
        components={{
          Actions: (props) => {
            return props.actions[0].tooltip === 'Add' ? (
              <div className={classes.actionWrapper}>
                <Button
                  disabled={!allowEdit}
                  variant="contained"
                  primary
                  className={classes.action}
                  onClick={() => {
                    setIsEdit(false);
                    setCurrBusinessLine(null);
                    toggleModal();
                  }}
                >
                  <AddBox className={classes.addIcon} />
                  Create Business Line
                </Button>
              </div>
            ) : (
              <div>
                <IconButton
                  disabled={!allowEdit}
                  onClick={() => {
                    setCurrBusinessLine({
                      businessLine: props.data.businessLine,
                      threshold: props.data.threshold
                    });
                    setIsEdit(true);
                    toggleModal();
                  }}
                >
                  <Edit />
                </IconButton>
              </div>
            );
          }
        }}
      />
    </div>
  );
}
