import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocationForm from './LocationForm';
import { useNotificationDispatch } from '../Context/NotificationContext';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  formWrapper: { marginLeft: '3%' }
}));

export default function LocationFormModal({
  modalOpen,
  toggleModal,
  formState,
  codes,
  orgLookup,
  setFormState,
  isRequest
}) {
  const classes = useStyles();
  const { toggleAlert } = useNotificationDispatch();
  return (
    <div>
      <Dialog
        open={modalOpen}
        maxWidth='lg'
        fullWidth={true}
        onClose={toggleModal}
        aria-labelledby='Form input'
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id='New Location'>New Location</DialogTitle>
        <div className={classes.formWrapper}>
          <LocationForm
            formState={formState}
            setFormState={setFormState}
            orgLookup={orgLookup}
            codes={codes}
            toggleAlert={toggleAlert}
            toggleModal={toggleModal}
            isRequest={isRequest}
          />
        </div>
      </Dialog>
    </div>
  );
}
