import React from 'react';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
// Move to styled components
const useStyles = makeStyles((theme) => ({
  active: { marginTop: '2%', marginLeft: '13.5%' },
  descTextField: { width: '50%' }
}));

export default function DescriptionFieldGroup({
  formState,
  handleValueChange
}) {
  const classes = useStyles();
  return (
    <div>
      <TextField
        id="location_description"
        label="location description"
        className={classes.descTextField}
        value={formState.location_description || ''}
        onChange={(event) => {
          handleValueChange({
            name: 'location_description',
            newValue: event.target.value
          });
        }}
        margin="normal"
      />
      <div className={classes.divider} />

      {formState.is_colocated && (
        <TextField
          id="derived_location_description"
          label="derived location description"
          className={classes.descTextField}
          value={formState.derived_location_description || ''}
          onChange={(event) => {
            handleValueChange({
              name: 'derived_location_description',
              newValue: event.target.value
            });
          }}
          margin="normal"
        />
      )}
    </div>
  );
}
