import React from 'react';
import { fetchClient } from '../Utils/globalUtils';
import { Button } from '@amzn/stencil-react-components/button';
import Divider from '@material-ui/core/Divider';
export const ApprovalButtons = ({
  rowData,
  setNotificationState,
  jwtToken
}) => {
  const sendApproval = async ({
    isApproved,
    taskToken,
    alias,
    time,
    locationCode
  }) => {
    await fetchClient({
      url: 'approval',
      jwtToken,
      params: { isApproved, taskToken, alias, time, locationCode }
    });
    const message = isApproved === 'true' ? 'approved' : 'denied';
    setNotificationState({
      open: true,
      message: `Request successfully ${message}!`
    });
  };

  return (
    <div>
      <Button
        secondary
        aria-label="Delete"
        onClick={() => {
          sendApproval({
            isApproved: 'true',
            taskToken: rowData.taskToken
          });
        }}
      >
        Approve
      </Button>
      <Divider />
      <Button
        primary
        aria-label="Delete"
        onClick={() => {
          const { derived_location_code: locationCode } = JSON.parse(
            rowData.requestData
          );
          sendApproval({
            isApproved: 'false',
            taskToken: rowData.taskToken,
            alias: rowData.requestAlias,
            time: rowData.requestTime,
            locationCode
          });
        }}
      >
        Deny
      </Button>
    </div>
  );
};
