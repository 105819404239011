import React, { createContext, useState, useContext, useEffect } from 'react';
import Loader from '../common/Loader';
import { Typography } from '@material-ui/core';
import Axios from 'axios';
import { Auth, Hub, Logger } from 'aws-amplify';

const EmpInfoContext = createContext();

function EmpInfoProvider({ children }) {
  // Sets up coginto stuff to start the Auth flow
  const [authState, setAuthState] = useState({ loading: true });
  const [empInfo, setEmpInfo] = useState();
  const [isSuperUser, setIsSuperUser] = useState(false);
  useEffect(() => {
    const setSession = (session, config) => {
      setAuthState({
        loading: false,
        stage: config.Stage,
        session
      });
      const ldap = session.idToken.payload.locale
        ? session.idToken.payload.locale
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .map((g) => g.trim())
        : [];
      const posix = session.idToken.payload.zoneinfo
        ? session.idToken.payload.zoneinfo
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .map((g) => g.trim())
        : [];
      setEmpInfo({
        GIVEN_NAME: session.idToken.payload.given_name,
        FAMILY_NAME: session.idToken.payload.family_name,
        sub: session.idToken.payload['cognito:username'].split('_')[1],
        groups: [...ldap, ...posix],
        allowEdit:
          posix.includes('pisupport') ||
          posix.includes('peopleinsightbie') ||
          posix.includes('bliprequests') ||
          ldap.includes('blip-approvers')
      });
    };

    async function authenticate() {
      let shouldSignIn = true;

      Hub.listen('auth', (data) => {
        // eslint-disable-next-line default-case
        switch (data.payload.event) {
          case 'signIn':
            // Sign In successful, update session within state
            Auth.currentSession()
              .then((session) => {
                setSession(session, config);
              })
              .catch((err) => console.log(err));
            break;
          case 'codeFlow':
            // We are processing callback, signIn is not necessary
            shouldSignIn = false;
            break;
        }
      });

      const { data: config } = await Axios.get('/config/config.json');
      Logger.LOG_LEVEL = config.logLevel || 'DEBUG';
      config.Auth.oauth.redirectSignIn = window.location.origin;
      config.Auth.oauth.redirectSignOut = window.location.origin;
      Auth.configure(config.Auth);

      try {
        const session = await Auth.currentSession();
        setSession(session, config);
      } catch (e) {
        // No active session, begin sign in unless we are processing callback
        if (shouldSignIn) {
          // By providing a value for customProvider we avoid redirecting to
          // cognito's hosted UI which requires the user to click a sign in
          // button
          Auth.federatedSignIn({ customProvider: '' });
        }
      }
    }
    authenticate();
  }, [setEmpInfo, setAuthState]);
  if (authState.loading) return <Loader />;
  return (
    <EmpInfoContext.Provider
      value={{ authState, empInfo, setEmpInfo, isSuperUser, setIsSuperUser }}
    >
      {empInfo ? (
        empInfo instanceof Error ? (
          <Typography variant="h2">
            You are not authorized to view this page
          </Typography>
        ) : (
          children
        )
      ) : (
        <Loader />
      )}
    </EmpInfoContext.Provider>
  );
}

function useEmpInfoState() {
  const context = useContext(EmpInfoContext);
  if (context === undefined) {
    throw new Error('EmpInfoState must be used within a EmpInfoProvider');
  }
  return context;
}

export { EmpInfoProvider, useEmpInfoState };
