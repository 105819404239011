import React, { useEffect, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@amzn/stencil-react-components/button';
import AddBox from '@material-ui/icons/AddBox';
import Loader from '../common/Loader';
import LocationFormModal from './LocationFormModal';
import { useNotificationDispatch } from '../Context/NotificationContext';
import { useEmpInfoState } from '../Context/EmpInfoContext';
import { tableIcons } from '../Utils/tableUtils';
import ActionButtons from '../Request/ActionButtons';
import { fetchClient, groupBy, groupByCustom } from '../Utils/globalUtils';
import Tree from 'react-d3-tree';
import { Grid, IconButton, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DraggableList from './Form/DraggableList';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  table: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    overflowY: 'hidden'
  },
  toggleButton: {
    marginBottom: '10px'
  },
  addButtonWrapper: { marginLeft: '70%', marginBottom: '15px' },
  addButton: { marginLeft: 'auto' },
  inlineActionsWrapper: { overflow: 'hidden' },
  addIcon: { marginRight: '2%' },
  locationWrapper: {
    padding: '1%',
    overflowX: 'hidden',
    width: '100vw',
    height: '100%',
    position: 'relative'
  },
  treeWrapper: {
    position: 'relative',
    display: 'block',
    height: '100%'
  },
  fcMap: {
    height: '100%',
    width: '100%'
  },
  viewNav: {
    ...theme.typography.button,
    backgroundColor: '#f7f8fa',
    padding: theme.spacing(1)
  }
}));

// Set up default values for creating a new location
const currDate = new Date();
const lang = window.navigator.userLanguage || window.navigator.language;
const formattedCurrDate = currDate.toLocaleDateString(lang);

export const initFormState = ({ username }) => {
  const initState = {
    location_id: undefined,
    old_derived_code: undefined,
    location_code: 'Ex: ORD2',
    derived_location_code: undefined,
    is_colocated: false,
    is_quickpay: false,
    location_description: undefined,
    derived_location_description: undefined,
    geo: undefined,
    org: undefined,
    begin_date: formattedCurrDate,
    end_date: '12/31/2999',
    country_code: undefined,
    city_name: undefined,
    business_line: 'Ex: AMZL',
    subdivision: undefined,
    observes_dst: false,
    update_userid: username
  };
  return initState;
};

export default function LocationTable() {
  // Handles modal visiblity for the location input form
  const [modalOpen, toggleModalOpen] = useState(false);
  //Handles in memory storage for the views
  const [state, setState] = useState({ loading: true, tableState: null });

  const [levelOrdering, setLevelOrdering] = useState({
    items: [
      { id: 'geo', primary: 'General Region', secondary: 'e.g NA' },
      {
        id: 'country_code',
        primary: 'country code',
        secondary: 'e.g US'
      },
      {
        id: 'org',
        primary: 'organization',
        secondary: 'e.g LM'
      },
      {
        id: 'business_line',
        primary: 'Business Line',
        secondary: 'e.g Air'
      },
      {
        id: 'location_code',
        primary: 'Location Code',
        secondary: 'e.g SEA36'
      }
    ]
  });
  const { toggleAlert, setNotificationState } = useNotificationDispatch();
  const {
    empInfo: { sub: username, allowEdit },
    authState: { session }
  } = useEmpInfoState();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const initState = initFormState({ username });
  const [formState, setFormState] = useState(initState);

  // Sets up the state and setters for stepping through our different views
  const [activeStep, setActiveStep] = React.useState(0);
  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
  function handlePrev() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const classes = useStyles();

  const resetForm = () => {
    setFormState(initState);
  };

  const toggleModal = useCallback(() => {
    toggleModalOpen(!modalOpen);
  }, [modalOpen]);

  // This handles edit and delete
  const handleMutation = useCallback(
    async ({ currentState }) => {
      const { tableData, ...rest } = currentState;
      const compareHash = {
        ...formState,
        ...rest
      };
      const data = await fetchClient({
        url: 'getLocks',
        jwtToken: session.idToken.jwtToken,
        params: { locationCode: currentState.derived_location_code }
      });
      setFormState({
        ...formState,
        old_derived_code: currentState.derived_location_code,
        parent_business_line: !rest.is_colocated ? rest.business_line : null,
        ...rest,
        update_userid: username,
        id: currentState.location_id,
        global_region: currentState.geo
      });
      if (data.length === 0 || data[0].hash === JSON.stringify(compareHash)) {
        toggleModal();
      } else {
        setNotificationState({
          open: true,
          message:
            'This location already has an edit in the pipeline and is locked'
        });
      }
    },
    [
      formState,
      session.idToken.jwtToken,
      username,
      toggleModal,
      setNotificationState
    ]
  );

  // Recursively builds a hierarchial representation recursively
  const buildTree = useCallback(({ data, levelOrdering, level }) => {
    // Fetches the key for the current level
    const levelName = levelOrdering.items[level].id;

    // Creates groups based on the specified attribute
    const groupedItems = groupBy({ arr: data, criteria: levelName });

    // loops through each value related to the key
    const stuff = Object.keys(groupedItems).map((key) => {
      // As long as we aren't at a leaf(or early stopping point) recursively call
      // this function and return an object containing children and the value
      if (level < levelOrdering.items.length - 1) {
        const children = buildTree({
          data: groupedItems[key],
          levelOrdering,
          level: level + 1
        });
        return { name: key, children, _collapsed: true };

        // base case to prevent infinite recursion(hits a leaf as specified by the ordering)
      } else {
        return { name: key, item: groupedItems[key] };
      }
    });
    return stuff;
  }, []);
  const orgsBusinessLine = (data) => {
    const groupedItems = groupByCustom({
      arr: data,
      criteria: 'business_line',
      filter: 'org'
    });
    groupedItems[''] = new Set(['']);
    groupedItems[null] = null;
    return groupedItems;
  };

  const customIsActive = (rowData) => {
    return rowData.is_active === 't' ? <CheckIcon /> : <CloseIcon />;
  };
  const customIsColocated = (rowData) => {
    return rowData.is_colocated === 't' ? <CheckIcon /> : <CloseIcon />;
  };

  // Fetches the location data from backend and formats it into an object that's readable by the mui table
  useEffect(() => {
    async function execute() {
      let data;

      // Client side caching mechanism for faster UX
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const hiddenCols = new Set([
        'latitude',
        'longitude',
        'is_quickpay',
        'location_id',
        'county'
      ]);
      data = await fetchClient({
        url: 'getHierarchy',
        jwtToken: session.idToken.jwtToken
      });

      // Parse out all the data into a format useable by our components
      if (data && data !== 'Forbidden') {
        const keys = Object.keys(data[0]);
        const codes = data.map((item) => item.derived_location_code);
        
        const columns = keys.map((key) => {
          const title =
            key
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
          const appVal=(key==='is_active'|| key==='is_colocated')? '(t/f)': '';
          const col = { title: title+appVal+'\n', field: key };
          if (hiddenCols.has(key)) {
            return { ...col, hidden: true };
          } else if (key === 'is_active') {
            return {
              ...col,
              render: (rowData) => customIsActive(rowData)
            };
          } else if (key === 'is_colocated') {
            return {
              ...col,
              render: (rowData) => customIsColocated(rowData)
            };
          }

          return col;
        });
        setState({
          loading: false,
          codes: new Set(codes),
          tableState: { columns, data },
          treeState: [
            {
              name: 'Locations',
              children: buildTree({
                data,
                levelOrdering,
                level: 0
              })
            }
          ]
        });
      }
    }

    execute();
  }, [buildTree, levelOrdering, session.idToken.jwtToken]);

  // Checks if we are still fetching the data and returns the loader
  if (state.loading) {
    return <Loader />;
  }

  // Constructs an array with the type of view and the corresponding component to simplify the render return
  const viewArr = [
    {
      value: 'Table',
      component: (
        <MaterialTable
          icons={tableIcons}
          className={classes.table}
          title="Locations"
          columns={state.tableState.columns}
          data={state.tableState.data}
          // We need to have an empty object in order for mui table to render the actions column and add button
          editable={{
            onRowAdd: {},
            onRowUpdate: {},
            onRowDelete: {}
          }}
          options={{
            pageSize: 50,
            pageSizeOptions:[5, 10, 20, 30, 40, 50],
            filtering: true
          }}
          // Component overriding for add button and actions since we have too many attributes to modify inline
          components={{
            Actions: (props) =>
              props.actions[0].tooltip === 'Add' ? (
                <div />
              ) : (
                // We have to use the grid here for proper formatting otherwise the
                // buttons stack on top of one another and it looks horrid.
                <div className={classes.inlineActionsWrapper}>
                  <ActionButtons
                    props={props}
                    handleMutation={handleMutation}
                    allowEdit={allowEdit}
                  />
                </div>
              )
          }}
        />
      )
    },
    {
      value: 'Tree',
      component: (
        <div className={classes.treeWrapper}>
          <Grid container spacing={1}>
            <Grid item xs>
              <div>
                <DraggableList
                  state={levelOrdering}
                  setState={setLevelOrdering}
                />
              </div>
            </Grid>
            <Grid item xs={10}>
              <Tree
                data={state.treeState}
                translate={{
                  x: (dimensions.width / 2) * 0.5,
                  y: (dimensions.height / 2) * 0.2
                }}
                orientation={'vertical'}
                pathFunc={'straight'}
                onClick={(nodeData, evt) => {
                  if (nodeData.item)
                    handleMutation({ currentState: nodeData.item[0] });
                }}
              />
            </Grid>
          </Grid>
        </div>
      )
    },
    {
      value: 'Map',
      component: (
        <iframe
          className={classes.fcMap}
          title="TopologyMap"
          src="https://fcmap.topology.a2z.com/"
        />
      )
    }
  ];

  //Actually renders everything out
  return (
    <div className={classes.locationWrapper}>
      <LocationFormModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        formState={formState}
        orgLookup={orgsBusinessLine(state.tableState.data)}
        codes={state.codes}
        setFormState={setFormState}
        toggleAlert={toggleAlert}
      />
      <div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <IconButton onClick={handlePrev}>
              <ChevronLeft />
            </IconButton>
            <Typography display="inline" className={classes.viewNav}>
              {viewArr[Math.abs(activeStep % 3)].value}
            </Typography>
            <IconButton onClick={handleNext}>
              <ChevronRight />
            </IconButton>
          </Grid>

          <Grid item xs={6}>
            <Button
              disabled={!allowEdit}
              variant="contained"
              className={classes.addButtonWrapper}
              primary
              aria-label="Create Location"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              <AddBox className={classes.addIcon} />
              Create Location
            </Button>
          </Grid>
        </Grid>
      </div>
      {viewArr[Math.abs(activeStep % 3)].component}
    </div>
  );
}
