import React, { useState } from 'react';
import LocationTable from './Location/LocationTable';
import NavigationBar from './common/Navbar';
import BusinessLineTable from './BusinessLine/BusinessLineTable';
import RequestTable from './Request/RequestTable';
import { NotificationProvider } from './Context/NotificationContext';
import NotificationWrapper from './common/NotifWrapper';
import { EmpInfoProvider } from './Context/EmpInfoContext';
import './css/App.css';

function App() {
  // Handles state of current tab
  const tableArr = [LocationTable, BusinessLineTable, RequestTable];
  const [currentTab, setcurrentTab] = useState({
    value: 0,
    component: tableArr[0]
  });
  // Handles routing for tabs
  const handleChange = (event, newTab) => {
    setcurrentTab({ value: newTab, component: tableArr[newTab] });
  };

  return (
    <NotificationProvider>
      <EmpInfoProvider>
        <div id='App' className='App'>
          <NavigationBar
            currentTab={currentTab.value}
            handleChange={handleChange}
          />
          <NotificationWrapper />

          <currentTab.component />
        </div>
      </EmpInfoProvider>
    </NotificationProvider>
  );
}

export default App;
